import React from 'react';
import classNames from 'classnames';
import { ArticleNew } from '@/types/articleTypes';
import { decodeHtmlEntity } from '@/helpers/htmlEntityDecode/htmlEntityDecode';
import { ArticleCardCompact } from '../articles/ArticleCardCompact';

export interface SidebarArticlesProps {
	containerClassName?: string;
	headingText?: string;
	filteredArticles: ArticleNew[] | null;
	testId?: string;
}

const SidebarArticles = (props: SidebarArticlesProps) => {
	const {
		containerClassName,
		headingText,
		filteredArticles,
		testId = 'sidebar-articles',
	} = props;
	if (!filteredArticles || filteredArticles.length < 1) return null;
	return (
		<div data-testid={testId} className={classNames(containerClassName)}>
			<div className="mb-10 grid gap-4 lg:mb-12">
				<h3 className="mb-2 text-center text-2xl font-semibold tracking-tight lg:text-left lg:text-4xl">
					{headingText}
				</h3>

				{filteredArticles?.map((article: ArticleNew) => {
					return (
						<div key={article.id}>
							<ArticleCardCompact
								href={`/expert-advice/${article.slug}`}
								title={decodeHtmlEntity(article.title)}
								category={article.category?.title}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SidebarArticles;
