import cn from 'classnames';
import Link from 'next/link';
import { HeadingText } from '../../atoms/HeadingText';
import {
	IconAir,
	IconCircleQuestionMark,
	IconFlame,
	IconSnowflake,
	IconHeatPump,
	IconHumidity,
	IconAnalogClock,
} from '@/components/icons';

export interface ArticleCardCompactProps {
	href: string;
	title: string;
	readTime?: number;
	category?: string;
	testId?: string;
}

const ArticleCardCompact = (props: ArticleCardCompactProps) => {
	const {
		category,
		href,
		testId = 'ArticleCardCompact',
		title,
		readTime,
	} = props;

	const isAirConditioners = category === 'Air Conditioners';
	const isAirQuality = category === 'Air Quality';
	const isHeatPumps = category === 'Heat Pumps';
	const isFurnaces = category === 'Furnaces';
	const isTroubleshooting = category === 'Troubleshooting';
	const isHumidity = category === 'Humidity';

	return (
		<Link
			href={href}
			className={cn(
				'block h-full rounded-3xl border-2 border-slate-300 p-4 transition-all duration-100 hover:cursor-pointer hover:border-black lg:p-6',
			)}
			data-testid={testId}
		>
			<article className={'flex flex-col justify-between lg:h-full'}>
				{category && (
					<div className="flex items-center justify-between">
						<div className="flex items-center">
							<div className="mr-1 h-7 w-7 lg:mr-2 lg:h-8 lg:w-8">
								{isAirConditioners && (
									<IconSnowflake className="max-w-full fill-sky-700" />
								)}
								{isAirQuality && <IconAir className="fill-sky-700" />}
								{isHeatPumps && <IconHeatPump className="fill-sky-700" />}
								{isFurnaces && <IconFlame className="fill-sky-700" />}
								{isTroubleshooting && (
									<IconCircleQuestionMark className="fill-sky-700" />
								)}
								{isHumidity && <IconHumidity className="fill-sky-700" />}
							</div>
							<div
								className={cn(
									'flex h-6 max-w-fit items-center rounded-md px-2 py-1 text-sm lg:h-7',
									'bg-sky-50 font-medium text-sky-700',
								)}
							>
								{category}
							</div>
						</div>

						{readTime ? (
							<span className="ml-3 flex items-center justify-center text-xs text-slate-500 lg:ml-6 lg:text-sm">
								<IconAnalogClock className="h-5 w-5 fill-slate-500 lg:w-6" />{' '}
								{readTime} min read
							</span>
						) : null}
					</div>
				)}

				<HeadingText tag="h3" size="h4" className="mt-4 !font-medium lg:mt-6">
					{title}
				</HeadingText>
			</article>
		</Link>
	);
};

export default ArticleCardCompact;
